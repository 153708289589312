.error {
  border: 1px solid #F44336;
}

.error>input {
  border: none;
}

.datepicker {
  max-width: 215px;
  width: 10vw;
  border: 1px solid #E4E6E8;
  border-radius: 8px;
  background-image: url(../../assets/icons/Calendar.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 95% 50%;
  font-size: max(12px, 0.71vw);
  color: #333333;
  padding-right: 36px;
  padding-left: clamp(12px, calc(12vw - 190px), 20px);
  padding-top: 14px;
  padding-bottom: 13px;
}

.datepicker>input {
  border: none;
}

.react-datepicker-popper {
  z-index: 1400;
}

.react-datepicker-wrapper {
  display: inline;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  font-family: "Avenir Next Cyr";
  color: '#333333';
}

body {
  margin: 0;
  padding: 0;
}

#donut .c3-tooltip {
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
  color: #868C99;
  border: none;
  background-color: #FFF;
  border-radius: 8px;
  border-collapse: unset;
  border-spacing: unset;
  opacity: 0.95;
  padding: 4px;

}

#donut .c3-tooltip tr {
  border: none;
  border-radius: 8px;
  padding: 4px;
}

#donut .c3-tooltip th {
  border: none;
  border-radius: 8px;
}


#donut .c3-tooltip td {
  display: table-caption;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Avenir Next Cyr';
  font-weight: 600;

}

#donut .c3-tooltip td>span {
  display: inline-block;
  width: 10px;
  height: 10px;

}